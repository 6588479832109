import { z } from "zod";
import { StoragePayload } from "../nativeIntegration/types";
import appLogger from "../utils/logger";
import { DataStorage } from "./DataStorage";

const logger = appLogger.with({ namespace: "WebViewStorageHandler" });

const SerializedBlobSchema = z.object({
  _type: z.literal("blob"),
  data: z.string(),
  type: z.string(),
});
type SerializedBlob = z.infer<typeof SerializedBlobSchema>;

const SerializedNumberSchema = z.object({
  _type: z.literal("number"),
  value: z.number(),
});
type SerializedNumber = z.infer<typeof SerializedNumberSchema>;

const SerializedDateSchema = z.object({
  _type: z.literal("date"),
  value: z.string(),
});
type SerializedDate = z.infer<typeof SerializedDateSchema>;

// Because data is ultimately stored as a string in the SQLite database, we need to go through returned
// objects and parse any date strings into Date objects.
const deserialize = (obj: any): any => {
  if (!obj) return obj;
  const maybeSerializedBlob = SerializedBlobSchema.safeParse(obj);
  if (maybeSerializedBlob.success) {
    const serializedBlob = maybeSerializedBlob.data;
    const base64 = serializedBlob.data.split(",")[1];
    const byteCharacters = window.atob(base64);
    const byteArray = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArray[i] = byteCharacters.charCodeAt(i);
    }
    return new Blob([byteArray], { type: serializedBlob.type });
  }
  const maybeSerializedNumber = SerializedNumberSchema.safeParse(obj);
  if (maybeSerializedNumber.success) {
    return maybeSerializedNumber.data.value;
  }
  const maybeSerializedDate = SerializedDateSchema.safeParse(obj);
  if (maybeSerializedDate.success) {
    return new Date(maybeSerializedDate.data.value);
  }
  if (Array.isArray(obj)) {
    return obj.map(deserialize);
  }
  if (typeof obj === "object") {
    const updated: any = {};
    for (const [k, v] of Object.entries(obj)) {
      updated[k] = deserialize(v);
    }
    return updated;
  }
  return obj;
};

const serialize = async (obj: any): Promise<any> => {
  if (!obj) return obj;
  if (obj instanceof Blob) {
    const reader = new FileReader();
    reader.readAsDataURL(obj);
    return (await new Promise((resolve) => {
      reader.onloadend = () => {
        resolve({
          _type: "blob",
          data: reader.result as string,
          type: obj.type,
        });
      };
    })) satisfies SerializedBlob;
  }
  if (typeof obj === "number") {
    return { _type: "number", value: obj } satisfies SerializedNumber;
  }
  if (obj instanceof Date) {
    return { _type: "date", value: obj.toISOString() } satisfies SerializedDate;
  }
  if (Array.isArray(obj)) {
    return await Promise.all(obj.map(serialize));
  }
  if (typeof obj === "object") {
    const updated: any = {};
    for (const [k, v] of Object.entries(obj)) {
      updated[k] = await serialize(v);
    }
    return updated;
  }
  return obj;
};

class WebViewStorageHandler implements DataStorage {
  constructor(
    private nextRequestId = 0,
    private pendingRequests: Map<number, { resolve: (value: any) => void; reject: (reason?: any) => void }> = new Map(),
  ) {}

  async findAllKeys(): Promise<string[]> {
    logger.info("findAllKeys");
    try {
      const response = (await this.postMessage("findAllKeys")) as string[];
      return response;
    } catch (e) {
      logger.error("Error in findAllKeys", { error: e });
      throw e;
    }
  }
  async findAllKeysWithPrefix(prefix: string): Promise<string[]> {
    logger.info("findAllKeysWithPrefix", { context: { prefix } });
    try {
      const response = (await this.postMessage({ action: "findAllKeysWithPrefix", prefix: prefix })) as string[];
      return response;
    } catch (e) {
      logger.error("Error in findAllKeysWithPrefix", { error: e });
      throw e;
    }
  }

  async getItem<T>(key: string): Promise<T | null> {
    logger.info("getItem", { context: { key } });
    try {
      const response = await this.postMessage({ action: "getItem", key: key });
      if (!response || response.length === 0) {
        return null;
      }
      const parsed = deserialize(JSON.parse(response));
      return parsed;
    } catch (e) {
      logger.error("Error in getItem", { error: e, context: { key } });
      throw e;
    }
  }
  async getItems<T>(keys: string[]): Promise<T[]> {
    logger.info("getItems", { context: { keys } });
    let response = [];
    try {
      response = await this.postMessage({ action: "getItems", keys: keys });
      if (!Array.isArray(response)) {
        throw new Error("Response is not an array");
      }
      return response.map((r: any) => deserialize(JSON.parse(r))) as T[];
    } catch (e) {
      logger.error("Error in getItems", {
        error: e,
        context: {
          keys,
          response,
        },
      });
      throw e;
    }
  }

  async setItem(key: string, val: any): Promise<void> {
    logger.info("setItem", { context: { key } });
    try {
      await this.postMessage({ action: "setItem", key: key, value: await serialize(val) });
    } catch (e) {
      logger.error("Error in setItem", { error: e, context: { key, val } });
      throw e;
    }
  }
  async setItems(keyValues: { key: string; val: any }[]): Promise<void> {
    console.log("setItems", { keyValues });
    const serializedKeyValues = await Promise.all(
      keyValues.map(async ({ key, val }) => ({ key, val: await serialize(val) })),
    );
    try {
      await this.postMessage({
        action: "setItems",
        items: serializedKeyValues,
      });
    } catch (e) {
      logger.error("Error in setItems", { error: e, context: { keyValues } });
      throw e;
    }
  }

  async removeItem(key: string): Promise<void> {
    logger.info("removeItem", { context: { key } });
    try {
      await this.postMessage({ action: "removeItem", key: key });
    } catch (e) {
      logger.error("Error in removeItem", { error: e, context: { key } });
      throw e;
    }
  }
  async removeItems(keys: string[]): Promise<void> {
    logger.info("removeItems", { context: { keys } });
    try {
      await this.postMessage({ action: "removeItems", keys: keys });
    } catch (e) {
      logger.error("Error in removeItems", { error: e, context: { keys } });
      throw e;
    }
  }
  async removeAllItems(): Promise<void> {
    logger.info("removeAllItems");
    try {
      await this.postMessage("removeAllItems");
    } catch (e) {
      logger.error("Error in removeAllItems", { error: e });
      throw e;
    }
  }

  postMessage(messageData: string | Record<string, any>): Promise<any> {
    const requestId = this.nextRequestId++;
    if (typeof messageData === "string") {
      messageData = { action: messageData };
    }
    const message = {
      requestId,
      ...messageData,
    };

    const promise = new Promise((resolve, reject) => {
      this.pendingRequests.set(requestId, { resolve, reject });
    });

    window.webkit?.messageHandlers.storage.postMessage(message);

    return promise;
  }

  handleStorageResponse(payload: StoragePayload) {
    const { requestId, result } = payload;
    if (!this.pendingRequests.has(requestId)) {
      logger.warn("Received response for unknown request", { context: { response: payload }, report: true });
      return;
    }

    const { resolve, reject } = this.pendingRequests.get(requestId)!;
    if (result.error) {
      reject(result.error);
    } else {
      resolve(result.data);
    }
    this.pendingRequests.delete(requestId);
  }
}

const webViewStorageHandler = new WebViewStorageHandler();
export default webViewStorageHandler;
