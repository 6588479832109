/**
 * This module is written in plain JavaScript (not TypeScript) so it can be used in both
 * the frontend application code and in next.config.js during the build process.
 * It provides utilities for generating consistent sentry release IDs across the application.
 */

function isProcessEnv(env) {
  return (
    env &&
    typeof env === "object" &&
    env.NEXT_PUBLIC_VERSION &&
    env.NEXT_PUBLIC_LATEST_COMMIT &&
    env.NEXT_PUBLIC_ENV &&
    env.NEXT_PUBLIC_RUNNING_IN_IOS_WEBVIEW
  );
}

function createSentryReleaseId(env) {
  if (!isProcessEnv(env)) return "local";
  const platform = env.NEXT_PUBLIC_RUNNING_IN_IOS_WEBVIEW ? "ios" : "web";
  return [env.NEXT_PUBLIC_VERSION, env.NEXT_PUBLIC_LATEST_COMMIT, env.NEXT_PUBLIC_ENV, platform].join("-");
}

module.exports = { createSentryReleaseId };
