import { useEffect, useSyncExternalStore } from "react";
import appLogger from "../../../utils/logger";
import { appNoteStore } from "../../../model/services";
import { isRunningInIOSWebview, isNativeRecordingEnabled } from "../../../utils/environment";
import { mapBlockTokens } from "../../../../shared/tokenIterators/mapBlockTokens";
import { Note } from "../../../../shared/types";
import { getEditorView } from "../../../model/atoms";
import { storage } from "../../../storage/storage";
import { ChunkMetadata } from "./audioTypes";
import { createMediaRecorder, MediaRecorder } from "./mediaRecorder";
import { createiOSMediaRecorder } from "./iOSMediaRecorder";
import { audioLogsPrefix, debouncedUploadAllAudio, decodeBase64ToMp3, deleteLocalAudio } from "./utils";

const logger = appLogger.with({ namespace: `${audioLogsPrefix}/coordinator` });

interface IEphemeralAudioAttrs {
  chunkCount: number;
  durations: number[] | null;
}

/**
 * This class coordinates recording of audio between multiple AudioInsertViews.
 * When a second AudioInsertView starts recording the first one should be stopped.
 * If the editor is refreshed (you can simulate it by calling window.refreshEditor)
 * the AudioRecordingCoordinator ensures the recording process is not dropped */
class AudioRecordingCoordinator {
  private stateListeners: (() => void)[] = [];

  /**
   * A temporary map used for source of truth when a `AudioInsertView` is created (or re-created on undo).
   * When a `AudioInsertView` node is deleted, we still need to store the updated
   * `chunkCount` and `durations` somewhere to reference them later.
   */
  private audioIdToAttrEphemeralMap = new Map<string, IEphemeralAudioAttrs>();
  private recordingState: {
    audioId: string;
    // `lastStartupTime` is null when paused
    lastStartupTime: number | null;
    mediaRecorder: MediaRecorder | null;
    state: "inactive" | "recording";
  } | null = null;

  private volumeValuesListeners: (() => void)[] = [];
  private volumeValues: number[] = [];
  useVolumeValues() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useSyncExternalStore(
      (listner) => {
        this.volumeValuesListeners.push(listner);
        return () => {
          this.volumeValuesListeners = this.volumeValuesListeners.filter((l) => l !== listner);
        };
      },
      () => this.volumeValues,
    );
  }

  public getAudioAttrs(audioId: string): IEphemeralAudioAttrs {
    return this.audioIdToAttrEphemeralMap.get(audioId) ?? { chunkCount: 0, durations: null };
  }

  public setAudioAttrs(audioId: string, attributes: IEphemeralAudioAttrs): void {
    this.audioIdToAttrEphemeralMap.set(audioId, attributes);
  }

  useRecordingState() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useSyncExternalStore(
      (listner) => {
        this.stateListeners.push(listner);
        return () => {
          this.stateListeners = this.stateListeners.filter((l) => l !== listner);
        };
      },
      () => this.recordingState,
    );
  }

  // If the recording is no longer needed, call this method
  abandonRecording(audioId: string) {
    if (this.recordingState?.audioId !== audioId) {
      return null;
    }
    const mediaRecorder = this.recordingState.mediaRecorder;
    if (mediaRecorder?.getState() !== "inactive") {
      mediaRecorder?.stop();
    }
    this.recordingState = null;
    this.stateListeners.forEach((l) => l());
    deleteLocalAudio(audioId).then(() => {
      this.audioIdToAttrEphemeralMap.delete(audioId);
    });
  }

  async startRecording(audioId: string) {
    if (this.recordingState?.audioId === audioId) {
      // This audioId is already recording: NOOP
      return;
    }
    if (this.recordingState?.audioId) {
      this.stopRecording(this.recordingState.audioId);
    }
    this.recordingState = {
      audioId,
      mediaRecorder: null,
      state: "inactive",
      lastStartupTime: Date.now(),
    };
    this.setAudioAttrs(audioId, {
      chunkCount: 0,
      durations: null,
    });
    this.stateListeners.forEach((l) => l());
    try {
      const mediaRecorder = await this.getMediaRecorder(audioId);
      this.recordingState = {
        audioId,
        mediaRecorder,
        state: mediaRecorder.getState(),
        lastStartupTime: Date.now(),
      };
      this.stateListeners.forEach((l) => l());
    } catch (error) {
      this.abandonRecording(audioId);
      throw error;
    }
  }

  async getMediaRecorder(audioId: string): Promise<MediaRecorder> {
    if (isRunningInIOSWebview && isNativeRecordingEnabled) {
      const mediaRecorder = await createiOSMediaRecorder({
        audioId,
        onStateChange: () => {
          this.recordingState = {
            ...this.recordingState!,
            state: mediaRecorder.getState(),
          };
          this.stateListeners.forEach((l) => l());
        },
        cleanup: () => {
          this.recordingState = null;
          this.stateListeners.forEach((l) => l());
          this.volumeValues = [];
        },
      });
      return mediaRecorder;
    } else {
      const mediaRecorder = await createMediaRecorder({
        audioId,
        onVolumeUpdate: (volume) => {
          this.volumeValues = [...this.volumeValues, volume];
          this.volumeValuesListeners.forEach((l) => l());
        },
        onStateChange: () => {
          this.recordingState = {
            ...this.recordingState!,
            state: mediaRecorder.getState(),
          };
          this.stateListeners.forEach((l) => l());
        },
        onStopFinished: async () => {
          logger.info("onStopFinished");
          // The upload should already be in progress from the onChunkReady handler
          // but we call it here to ensure the upload finishes before calling listeners
          await debouncedUploadAllAudio();
          const { chunkCount } = this.getAudioAttrs(audioId);
          this.savedListeners.callAll(audioId, { chunkCount });
          this.audioIdToAttrEphemeralMap.delete(audioId);
        },
        onChunkReady: async (data: ChunkMetadata) => {
          logger.info("onChunkReady", { context: data });
          updateAudioInsertTokens(audioId, data.chunkIndex, data.duration);
          await debouncedUploadAllAudio();
        },
        cleanup: () => {
          this.recordingState = null;
          this.stateListeners.forEach((l) => l());
          this.volumeValues = [];
        },
      });
      return mediaRecorder;
    }
  }

  handleiOSRecordingFailed(audioId: string) {
    this.abandonRecording(audioId);
  }

  async handleiOSAudioChunk(data: string, audioId: string, chunkIndex: number, duration: number) {
    const blob = decodeBase64ToMp3(data);
    const metadata = { audioId, chunkIndex, duration };
    logger.info("Saving chunk", { context: metadata });
    const idbPrefix = `audio-insert-${audioId}-${metadata.chunkIndex}`;

    // Start the IDB saving of the .mp3 and duration at the same time to avoid getting one and not the other
    // saved, especially in the interrupt situations
    await storage.setItems([
      { key: `${idbPrefix}.mp3`, val: blob },
      { key: `${idbPrefix}-duration`, val: duration },
    ]);
    logger.info("Chunk saved", { context: metadata });

    logger.info("Saving chunk index to audioInsert tokens");
    updateAudioInsertTokens(audioId, chunkIndex, duration);

    logger.info("Uploading audio to s3 by handleiOSAudioChunk");
    // This is purposefully not awaited. We just need to ensure the chunk is saved
    // in idb and the props are added to the audioInsert tokens.
    debouncedUploadAllAudio();
  }

  updateiOSAudioVolume(audioId: string, volume: number) {
    if (audioId !== this.recordingState?.audioId) {
      logger.error("Received volume data for the wrong audioId", { context: { audioId } });
      return;
    }

    this.volumeValues = [...this.volumeValues, volume];
    this.volumeValuesListeners.forEach((l) => l());
  }

  async finishiOSAudioRecording(audioId: string) {
    this.stopRecording(audioId);
    // Taken from onStopFinished
    logger.info("Uploading audio to s3 by finishiOSAudioRecording");
    await debouncedUploadAllAudio();
    const { chunkCount } = this.getAudioAttrs(audioId);
    logger.info("Calling savedListeners", { context: { audioId, chunkCount } });
    this.savedListeners.callAll(audioId, { chunkCount });
    this.audioIdToAttrEphemeralMap.delete(audioId);
  }

  stopRecording(audioId: string) {
    if (this.recordingState?.state !== "recording") {
      logger.error("Attempting to stop recording while not recording");
      return;
    }
    if (this.recordingState?.audioId !== audioId) {
      logger.error("Attempting to stop recording with an incorrect audioId");
      return;
    }
    logger.info("Stopping recording", { context: { audioId } });
    this.stopListeners.callAll(audioId, {});
    this.recordingState.mediaRecorder?.stop();
  }

  isRecording(audioId?: string) {
    if (!audioId) {
      return this.recordingState?.state === "recording";
    }
    return this.recordingState?.audioId === audioId;
  }

  savedListeners = createdListenersRegistry<{ chunkCount: number }>();
  stopListeners = createdListenersRegistry<object>();
}

function createdListenersRegistry<T>() {
  let listeners: {
    id: string;
    callback: (data: T) => void;
  }[] = [];
  function useRegisterListener(id: string, callback: (data: T) => void) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const obj = { id, callback };
      listeners.push(obj);
      return () => {
        listeners = listeners.filter((a) => a !== obj);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, callback]);
  }

  function callAll(id: string, data: T) {
    listeners.forEach((l) => l.id === id && l.callback(data));
  }

  return { callAll, useRegisterListener };
}

/**
 * Updates the audioInsert tokens with the new chunk count and durations. If the
 * audio is open in the editor, it updates the editor state. Otherwise it
 * updates the appNoteStore.
 */
function updateAudioInsertTokens(audioId: string, chunkIndex: number, duration: number) {
  const ephemeralAttrs = globalAudioRecordingCoordinator.getAudioAttrs(audioId);
  const newChunkCount = chunkIndex + 1;
  const newDurations = [...(ephemeralAttrs.durations ?? []), duration];
  if (newChunkCount !== newDurations.length) {
    logger.error("Inconsistent chunkCount and durations", {
      context: { chunkCount: newChunkCount, durations: newDurations, audioId },
    });
  }
  globalAudioRecordingCoordinator.setAudioAttrs(audioId, {
    chunkCount: newChunkCount,
    durations: newDurations,
  });
  let docChanged = false;
  const view = getEditorView();
  if (view) {
    const tr = view.state.tr;
    view.state.doc.descendants((node, pos) => {
      if (node.type.name === "audioInsert" && node.attrs.audioId === audioId) {
        tr.setNodeMarkup(pos, undefined, {
          ...node.attrs,
          chunkCount: newChunkCount,
          durations: newDurations,
        });
      }
    });
    if (tr.docChanged) {
      logger.info("Updating audioInsert tokens in editor state", { context: { audioId, chunkIndex, duration } });
      view.dispatch(tr);
      docChanged = true;
    }
  }
  if (!docChanged) {
    const clonedNotes = appNoteStore.audio
      .getNoteIdsForItem(audioId)
      .map((id) => appNoteStore.get(id))
      .filter((n): n is Note => n !== undefined)
      .map((n) => ({ ...n }));
    for (const note of clonedNotes) {
      for (const topLevelToken of note.tokens) {
        mapBlockTokens((blockToken) => {
          if (blockToken.type === "audioInsert" && blockToken.audioId === audioId) {
            blockToken.chunkCount = newChunkCount;
            blockToken.durations = newDurations;
          }
        })(topLevelToken);
      }
    }
    logger.info("Updating audioInsert tokens in appNoteStore", { context: { audioId, chunkIndex, duration } });
    appNoteStore.update(clonedNotes);
  }
}

export const globalAudioRecordingCoordinator = new AudioRecordingCoordinator();
