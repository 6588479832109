import { Fragment, Node } from "prosemirror-model";
import { EditorView } from "prosemirror-view";
import { trackEvent } from "../../../analytics/analyticsHandlers";
import { addToast } from "../../../components/Toast";
import { Logger } from "../../../utils/logger";
import { getParentNote } from "../../utils/find";
import { getTopLevelTokensFromContent, noteToProsemirrorNode } from "../../bridge";
import { makeParagraph } from "../../../model/defaults";
import { parseAsPastedText } from "../../utils/clipboard/transforms";
import { appNoteStore } from "../../../model/services";
import { schema } from "../../schema";
import { generateId } from "../../../model/generateId";
import { appendToNote } from "../note/appendToNote";
import { createAsyncExtractedEntitiesNode } from "../../../entities/createAsyncExtractedEntitiesNode";
import { isOfflineError } from "../../../utils/errorCheckers";
import { isLocal } from "../../../utils/environment";
import { ApiClient } from "../../../api/client";
import { getTranscriptionLanguage } from "./utils";
import { AudioAttrs } from "./audioTypes";

export const createTranscriptCallback = async (
  numChunks: number,
  audioId: string,
  audioInsertLogger: Logger,
  transcriptLanguageOverride: string | null,
  setAttrs: (attrOverride: Partial<AudioAttrs>, addToHistory?: boolean | undefined) => void,
  splitParagraphs: boolean = true,
) => {
  audioInsertLogger.info("Starting transcript generation", { context: { audioId, numChunks } });
  try {
    const language = getTranscriptionLanguage(transcriptLanguageOverride);
    trackEvent("transcribe_recording");
    const result = await ApiClient().transcripts.startTranscription({
      audioId,
      numChunks,
      language,
      splitParagraphs,
    });
    // check for errors
    if (result.error || !result.data) {
      throw new Error(`Response not ok, error: ${result.error} data: ${result.data}`);
    }
    audioInsertLogger.info("Transcript generation successful. Adding transcriptGenId to attrs", {
      context: { transcriptGenId: result.data },
    });
    setAttrs({
      transcriptGenId: result.data,
    });
  } catch (e) {
    if (!isOfflineError(e)) {
      audioInsertLogger.error("Transcribing audio failed", { error: e, report: true });
      if (isLocal) {
        addToast({ content: "Transcription failed. See console for details", type: "error" });
      } else {
        addToast("Transcription currently unavailable. We will automatically retry; please check again later.");
      }
      throw e;
    } else {
      audioInsertLogger.info("Audio transcription failed due to offline", { error: e, report: true });
    }
  }
};

export const insertTranscriptIntoNote = (
  transcript: string,
  getPos: () => number,
  editorView: EditorView,
  audioInsertLogger: Logger,
  longTranscriptThreshold = 1000,
) => {
  const tr = editorView.state.tr;

  // Get current note
  const [currentNoteNode, currentNotePos] = getParentNote(tr.doc, getPos());
  if (currentNoteNode === null) return;
  const currentNote = appNoteStore.get(currentNoteNode.attrs.noteId);
  if (currentNote === undefined) return;

  // Check if there's already a blank paragraph after the audio insert.
  // This is used later to determine whether to insert a blank paragraph
  const nextNode = tr.doc.nodeAt(getPos() + 1);
  const blankParagraphBelow = nextNode?.type === schema.nodes.paragraph && nextNode.textContent.match(/^\s*$/);

  // Insert the transcript into current note and/or new notes
  let insertedInCurrent = false;
  let posReferences = getPos() + 1;
  const references: Node[] = [];
  let pos = currentNotePos + currentNoteNode.nodeSize;
  let noteAbove = currentNote;
  for (const [i, text] of transcript.split("\n--\n").entries()) {
    const nodes = parseAsPastedText(text, editorView);
    if (i === 0 && text.length === 0) continue;
    if (i === 0 && text.length < longTranscriptThreshold) {
      // If the first transcript entry is short, insert it into the current note
      tr.insert(getPos() + 1, nodes);
      audioInsertLogger.info("Inserted transcript into current note", { context: { nodes } });
      // References will be inserted after the last note
      posReferences = getPos() + 2 + nodes.map((n) => n.nodeSize).reduce((a, b) => a + b, 0);
      insertedInCurrent = true;
      pos = tr.doc.nodeAt(currentNotePos)!.nodeSize;
    } else {
      // Try to parse the transcript or just split it into paragraphs
      const content = Fragment.from(nodes.map((n) => (n.isText ? schema.nodes.paragraph.create({}, [n]) : n)));
      const tokens = schema.nodes.note.validContent(content)
        ? getTopLevelTokensFromContent(content)
        : text.split("\n").map((t) => makeParagraph(t));
      // Insert an empty new note after the last note
      const [newNote] = appNoteStore.insertAfter(noteAbove.id, {
        tokens,
        expansionSetting: transcript.length > longTranscriptThreshold ? "collapse" : "auto",
      });
      // Save a reference to it
      const newNoteRef = schema.nodes.reference.create({
        tokenId: generateId(),
        linkedNoteId: newNote.id,
        content: appNoteStore.noteFormatter.getNoteEllipsis(newNote, (id) => appNoteStore.get(id)),
      });
      references.push(newNoteRef);
      // Insert the new note and then add the transcript to it
      tr.insert(pos, noteToProsemirrorNode(newNote));
      const newNoteNode = tr.doc.nodeAt(pos);
      audioInsertLogger.info("Inserted transcript into new note", { context: { newNote, newNoteNode, newNoteRef } });
      if (!newNoteNode) throw new Error("Lost track of position while inserting transcript in new note");
      pos += newNoteNode.nodeSize;
      noteAbove = newNote;
    }
  }

  // If new notes were inserted, add a paragraph with references to them
  const paragraphs = [];
  if (references.length > 0) {
    const s = references.length > 1 ? "s" : "";
    paragraphs.unshift(
      schema.nodes.paragraph.create({}, [
        schema.text(insertedInCurrent ? `Continued transcript${s}:` : `Transcript${s}:`, [
          schema.marks.italic.create(),
        ]),
        ...references.flatMap((r) => [schema.text(" "), r]),
      ]),
    );
  }
  if (!blankParagraphBelow) {
    // Insert empty paragraph to separate the transcript from the rest of the note
    paragraphs.push(schema.nodes.paragraph.create({}, []));
  }
  tr.insert(posReferences, paragraphs);

  editorView.dispatch(tr);
};

export const parseTranscriptAndInsert = (
  transcript: string,
  editorView: EditorView,
  getPos: () => number,
  onComplete: () => void,
) => {
  const posNote = getParentNote(editorView.state.doc, getPos())[1];
  if (posNote === null) return;
  const tr = editorView.state.tr;
  appendToNote(tr, posNote, createAsyncExtractedEntitiesNode(tr, transcript, transcript, onComplete));
  editorView.dispatch(tr);
};
